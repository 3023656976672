
import {defineComponent, h, reactive, ref, resolveComponent, UnwrapRef} from 'vue';
import {message} from "ant-design-vue";
import Employee from "@/request/Employee";
import moment from 'moment'
import SessionStorageKeys from "@/common/SessionStorageKeys";
import {UploadOutlined} from "@ant-design/icons-vue";
import Dict from "@/request/Dict";
import Company from "@/request/Company";

moment.locale('zh-cn')

interface FileItem {
    uid: string;
    name?: string;
    status?: string;
    response?: Response;
    url: string;
}

interface FileInfo {
    file: FileItem;
    fileList: FileItem[];
}

const companyId = ref();
const isAdmin = ref<boolean>(false)
export default defineComponent({
        components: {
            UploadOutlined,
        },
        name: 'Employee',
        beforeCreate() {
            isAdmin.value = sessionStorage.getItem(SessionStorageKeys.type)  === 'admin'
            companyId.value = isAdmin.value ? '' :Company.companyId;
        },
        setup() {
            const table = ref();

            const formRef = ref();

            const dateFormat = ref("YYYY-MM-DD");

            const selects: { value: string; }[] = [];
            const onCompanyChange = (data: any) => {
                params.companyId = data.item.id;
                formData.companyId = params.companyId;

                table.value.flush()

                const dictParams = reactive({
                    category: 'category',
                    companyId: params.companyId
                })

                Dict.getByCategory(dictParams).then((body: any) => {
                    if (body.data.length > 0) {
                        const data = body.data;
                        for (let i = 0; i < data.length; i++) {
                            selects.push({
                                value: data[i].name
                            })
                        }
                    }
                })
            }

            const params = reactive({
                companyId: companyId,
                name: '',
                idCard: '',
                phone: '',
                sex: ''
            })

            const formData: UnwrapRef<any> = reactive({
                id: null,
                name: '',
                code: '',
                certificate: '身份证',
                idCard: '',
                nature: '中国',
                sex: '男',
                phone: '',
                companyId: companyId,
                startTime: '',
                state: '在职',
                branch: '',
                category: '在编',
                account: '',
                remarks: '',
            })

            const visible = ref<boolean>(false);
            const impVisit = ref<boolean>(false);

            const edit = (data: any) => {
                visible.value = true
                formData.id = data.id
                formData.name = data.name
                formData.code = data.code
                formData.certificate = data.certificate
                formData.idCard = data.idCard
                formData.nature = data.nature
                formData.sex = data.sex
                formData.phone = data.phone
                formData.companyId = data.companyId
                formData.startTime = data.startTime
                formData.state = data.state
                formData.branch = data.branch
                formData.category = data.category
                formData.account = data.account
                formData.remarks = data.remarks
            }

            const showModel = () => {
                formRef.value ? formRef.value.resetFields() : '';
                visible.value = true;
                formData.id = null;
                formData.name = '';
                formData.code = '';
                formData.certificate = '身份证';
                formData.idCard = '';
                formData.nature = '中国';
                formData.sex = '男';
                formData.phone = '';
                formData.companyId = params.companyId;
                formData.startTime = '';
                formData.state = '在职';
                formData.branch = '';
                formData.category = '在编';
                formData.account = '';
                formData.remarks = '';
            };

            const onClose = () => {
                formRef.value.resetFields();
                visible.value = false;
            };

            const onSubmit = () => {
                formRef.value
                    .validate()
                    .then(() => {
                        Employee.save(formData).then((body: any) => {
                            if (body.code === 0) {
                                visible.value = false;
                                formRef.value.resetFields();
                                table.value.flush();
                                message.info('保存成功！')
                            } else {
                                message.error(body.msg);
                            }
                        });
                    })
            };

            const showUpload = () => {
                impVisit.value = true
                current.value = 0
                formRef2.value ? formRef2.value.resetFields() : "";
            };

            const closeUpload = (submit: boolean) => {
                impVisit.value = false
                if (submit) {
                    Employee.certain({companyId: params.companyId}).then((body: any) => {
                        if (body.code == 0) {
                            table.value.flush()
                            message.success('保存成功!')
                        } else {
                            message.error("保存失败！")
                        }
                    });
                }
            };

            const formRef2 = ref();

            const download = () => {
                let dom = document.createElement('a'); // 创建a标签
                dom.setAttribute('download', '员工信息模板');// download属性
                dom.setAttribute('href', `${process.env.VUE_APP_BASE_URL}` + '/人员信息采集导入模板.xls');// href链接
                dom.click()

                waiting.value = true
                setTimeout(() => {
                    next()
                    waiting.value = false
                }, 1000)
            };

            const current = ref<number>(0);
            const next = () => {
                current.value++;
            };
            const prev = () => {
                current.value--;
            };
            const waiting = ref<boolean>(false);

            const uploadData = ref<any>();
            const msg = ref<string>('');

            const handleChange = (info: FileInfo) => {
                let resFileList = [...info.fileList];

                resFileList = resFileList.slice(-2);

                resFileList.map(file => {
                    if (file.response) {
                        file.url = file.response.url;
                        if (file.response.code == 0) {
                            current.value = 2;
                            uploadData.value = file.response.data
                            msg.value = file.response.msg
                            message.info('上传成功！')
                        } else {
                            message.error(file.response.msg)
                        }
                    }
                    return file;
                });

            };

            return {
                path: ref(Employee.path),
                showCompanyList: ref<boolean>(false),
                msg,
                formRef,
                formRef2,
                formData,
                visible,
                closeUpload,
                uploadData,
                edit,
                impVisit,
                onClose,
                onSubmit,
                showModel,
                current,
                selects,
                next,
                prev,
                waiting,
                handleChange,
                headers: ref({
                    token: sessionStorage.getItem(SessionStorageKeys.token)
                }),
                uploadUrl: ref<string>(`${process.env.VUE_APP_BASE_URL}` + Employee.path + `/upload`),
                showUpload,
                dateFormat,
                download,
                table,
                params,
                onCompanyChange,
                rowSelection: ref({
                    onChange: (selectedRowKeys: (string | number)[], selectedRows: any) => {
                        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    },
                    onSelect: (record: any, selected: boolean, selectedRows: any) => {
                        console.log(record, selected, selectedRows);
                    },
                    onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {
                        console.log(selected, selectedRows, changeRows);
                    }
                }),
                search: ref([
                    {
                        tag: 'input',
                        label: '姓名',
                        key: 'name'
                    },
                    {
                        tag: 'input',
                        label: '身份证号',
                        key: 'idCard'
                    },
                    {
                        tag: 'input',
                        label: '手机号',
                        key: 'phone'
                    },
                    {
                        tag: 'select',
                        label: '性别',
                        key: 'gender',
                        width: '100px',
                        options: [
                            {
                                key: '1',
                                value: '男'
                            },
                            {
                                key: '2',
                                value: '女'
                            },
                            {
                                key: '3',
                                value: '未知'
                            }
                        ]
                    }
                ]),
                rules: {
                    name: [{required: true, message: '请输入姓名'}],
                    code: [{required: false, message: '请输入工号'}],
                    certificate: [{required: true, message: '请选择证件类型'}],
                    idCard: [{required: true, message: '请输入证件号'}],
                    nature: [{required: true, message: '请选择国籍'}],
                    phone: [{required: true, message: '请输入联系方式'}],
                    startTime: [{required: false, message: '请选择入职日期'}],
                    state: [{required: true, message: '请选择状态'}],
                    branch: [{required: false, message: '请输入部门'}],
                },
                actions: ref<Actions[]>([
                    {
                        name: '编辑',
                        event: 'edit'
                    },
                    {
                        name: '删除',
                        event: 'del'
                    }
                ]),
                columns: ref([
                    {
                        title: '工号',
                        dataIndex: 'code'
                    },
                    {
                        title: '姓名',
                        dataIndex: 'name'
                    },
                    {
                        title: '证件类型',
                        align: 'center',
                        dataIndex: 'certificate'
                    },
                    {
                        title: '证件号码',
                        dataIndex: 'idCard'
                    },
                    {
                        title: '国籍',
                        dataIndex: 'nature'
                    },
                    {
                        title: '性别',
                        align: 'center',
                        dataIndex: 'sex'
                    },
                    {
                        title: '手机号',
                        align: 'center',
                        dataIndex: 'phone'
                    },
                    {
                        title: '入职时间',
                        dataIndex: 'startTime'
                    },
                    {
                        title: '人员状态',
                        dataIndex: 'state',
                        align: 'center',
                        customRender: ({record}: any) => {
                            return h(
                                'div',
                                {},
                                h(resolveComponent('a-tag'), {
                                    color: (record.state == '在职' ? 'green' : record.state == '离职' ? 'red' : 'default'),
                                }, record.state)
                            );
                        }
                    },
                    {
                        title: '部门',
                        dataIndex: 'branch'
                    },
                    {
                        title: '人员类别',
                        dataIndex: 'category',
                        align: 'center',
                        customRender: ({record}: any) => {
                            return h(
                                'div',
                                {},
                                h(resolveComponent('a-tag'), {
                                    color: 'blue',
                                }, record.category)
                            );
                        }
                    },
                    {
                        title: '备注',
                        dataIndex: 'remarks'
                    },
                    {
                        title: '操作',
                        align: 'center',
                        key: 'action',
                        slots: {customRender: 'action'},
                        fixed: 'right',
                        width: 150
                    }
                ]),
                columns2: ref([
                    {
                        title: '关联',
                        dataIndex: 'match',
                        align: 'center',
                        customRender: ({record}: any) => {
                            return h(
                                'div',
                                {},
                                h(resolveComponent('a-tag'), {
                                    color: (record.match == 1 ? 'green' : record.match == 2 ? 'red' : ''),
                                }, (record.match == 1 ? '匹配成功' : record.match == 2 ? '匹配失败' : ''))
                            );
                        }
                    },
                    {
                        title: '工号',
                        dataIndex: 'code'
                    },
                    {
                        title: '姓名',
                        dataIndex: 'name'
                    },
                    {
                        title: '证件类型',
                        align: 'center',
                        dataIndex: 'certificate'
                    },
                    {
                        title: '证件号码',
                        dataIndex: 'idCard'
                    },
                    {
                        title: '国籍',
                        dataIndex: 'nature'
                    },
                    {
                        title: '性别',
                        align: 'center',
                        dataIndex: 'sex'
                    },
                    {
                        title: '手机号',
                        align: 'center',
                        dataIndex: 'phone'
                    },
                    {
                        title: '入职时间',
                        dataIndex: 'startTime'
                    },
                    {
                        title: '部门',
                        dataIndex: 'branch'
                    },
                    {
                        title: '人员类别',
                        dataIndex: 'category',
                        align: 'center',
                        customRender: ({record}: any) => {
                            return h(
                                'div',
                                [
                                    h(resolveComponent('a-tag'), {
                                        color: 'blue',
                                    }, record.category)
                                ]
                            );
                        }
                    },
                    {
                        title: '备注',
                        dataIndex: 'remarks'
                    }
                ]),
                steps: ref([
                    {
                        title: '下载表格模板',
                        content: '根据模板填写员工信息'
                    },
                    {
                        title: '表格上传',
                        content: '注意：必须上传模板格式一致的表格，单次最多添加1000人',
                    },
                    {
                        title: '结果核对及预览',
                        content: '核对表格数据正确性',
                    }]
                )
            }
        }
    }
)
