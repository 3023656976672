import {Request} from "@/Request";

class Employee extends Request {
    
    public path: string = '/employee';
    
    certain = (data: any) => {
        return this.formPost(`${this.path}/certain`, data)
    }
    
}

export default new Employee();